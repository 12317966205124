<template>
    <section class="route-container">
        <route-head-row title="howToPlay" />
        <div v-dompurify-html="$t('howToPlay.text')" class="instructions-container" />
    </section>
</template>

<script>
    import RouteHeadRow from '@/components/RouteHeadRow'
    const routeTitle = 'Πληροφορίες Για Το MAGICLAND - Οδηγός Συμμετοχής'
    export default {
        components: {
            RouteHeadRow
        },
        metaInfo() {
            return {
                title: routeTitle,
                meta: [
                    { name: 'robots', content: 'index, follow' },
                    { name: 'title', content: routeTitle },
                    { name: 'description', content: 'Όλα όσα πρέπει να ξέρεις για τη συμμετοχή σου στις αποστολές της Magicland! Διάβασε τον πλήρη οδηγό και ξεκίνα την περιήγησή σου στον μαγικό κόσμο της Magicland.' },
                    { property: 'og:title', content: routeTitle },
                    { property: 'og:description', content: 'Όλα όσα πρέπει να ξέρεις για τη συμμετοχή σου στις αποστολές της Magicland! Διάβασε τον πλήρη οδηγό και ξεκίνα την περιήγησή σου στον μαγικό κόσμο της Magicland.' }
                ]
            }
        }
    }
</script>

<style scoped>
.route-container {
    padding-bottom: 2rem;
}

.text-container {
    color: #fff;
}

.instructions-container {
    padding: 15px;
    margin: 1.5rem auto;
    width: 95%;
    max-width: 1320px;
    border-radius: 10px;
    text-align: justify;
    background: var(--ps-white);
    font-size: 1.1rem;
}

</style>
